"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupProductTechnical = setupProductTechnical;
const merge_1 = require("@walls/merge");
function setupProductTechnical(data) {
    // update the technical details
    const defaultValue = {
        formulas: [],
        name: "",
        requirements: {},
    };
    if (data.formulas) {
        defaultValue.formulas = data.formulas.map(value => Object.assign({
            name: "",
            value: "",
            default: false,
        }, value));
        delete data.formulas;
    }
    return (0, merge_1.assign)(defaultValue, data);
}
