"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createContactDetailsListItem = createContactDetailsListItem;
exports.createContactDetails = createContactDetails;
exports.getContactDetailsFromList = getContactDetailsFromList;
const merge_1 = require("@walls/merge");
/**
 * Create the sub-contact details
 * @param data
 * @returns
 */
function createContactDetailsListItem(data) {
    const defaultDetails = {
        uuid: "",
        label: "",
        email: "",
        isPrimary: false,
        phoneNumber: "",
        deleted: false,
    };
    return (0, merge_1.assign)(defaultDetails, data);
}
/**
 * Create the sub-contact details
 * @param data
 * @returns
 */
function createContactDetails(data) {
    const defaultDetails = {
        uuid: "",
        label: "",
        email: "",
        isPrimary: false,
        phoneNumber: "",
        deleted: false,
        mergedUuids: [],
    };
    return (0, merge_1.assign)(defaultDetails, data);
}
function getContactDetailsFromList(list, uuid) {
    // match the uuid
    if (uuid) {
        for (const details of list) {
            if (details.uuid === uuid) {
                return details;
            }
        }
    }
    // if not match found or uuid is not set try the primary details
    for (const details of list) {
        if (details.isPrimary) {
            return details;
        }
    }
    // if the other options fail. get the first contact details
    if (list[0]) {
        return list[0];
    }
    return null;
}
