"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateItem = calculateItem;
const mathjs_1 = require("mathjs");
const math_1 = require("../math");
/**
 * Calculate the given values for the InventoryItem
 *
 * @param tag the tag to the part that changed
 * @param item the item to calculate on
 * @param priceCategory this is no longer required
 * @param roundFormula the formula used to round off the selling price
 */
function calculateItem(tag, item, priceCategory, roundFormula) {
    const updatedProps = tag ? (Array.isArray(tag) ? tag : [tag]) : [];
    // ------------- Calculate the USD + CAD cost values -------------
    // set the USD Cost amount based on CAD cost
    if (updatedProps.includes("buyingPrice") && item.buyingPriceUSD > 0) {
        item.buyingPriceUSD = (0, math_1.roundPrice)(item.buyingPrice / item.exchangeRate);
    }
    // calculate the cost values
    if (item.buyingPriceUSD > 0) {
        item.buyingPrice = (0, math_1.roundPrice)(item.buyingPriceUSD * item.exchangeRate);
    }
    // ---------------------------------------
    if (updatedProps.includes("markupPercentage")) {
        const price = (0, math_1.addPercentage)(item.buyingPrice, item.markupPercentage);
        item.calculatedPrice = price;
        item.sellingPrice = price;
    }
    // calculate the selling price
    item.sellingPrice = (0, math_1.roundPrice)(item.sellingPrice, roundFormula);
    item.calculatedPrice = item.sellingPrice;
    // set the markup percentage for backward compatibility
    item.markupPercentage = (0, mathjs_1.round)((0, math_1.percentMarkup)(item.buyingPrice, item.sellingPrice), 6);
    // calculate the discounted prices
    for (const discount of item.discountedPrices) {
        discount.sellingPrice = (0, math_1.roundPrice)(discount.sellingPrice, roundFormula);
        discount.calculatedPrice = discount.sellingPrice;
    }
    // calculate the discount percentages after all of the discounts are calculated
    // this ensures that the parent discount price is calculated before attempting
    // the discount percent calculation.
    for (const discount of item.discountedPrices) {
        // set the discount percentage for backwards compatibility
        discount.discountPercent = (0, mathjs_1.round)((0, math_1.discountPercent)(item.getPrice(discount.parent).calculatedPrice, discount.sellingPrice), 6);
    }
    return item;
}
