"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createContactPreferredPricing = createContactPreferredPricing;
const merge_1 = require("@walls/merge");
/**
 * Create the default ContactPreferredPricing object
 *
 * @param params
 * @returns
 */
function createContactPreferredPricing(params) {
    const data = {
        productId: "",
        productName: "",
        sellingPrice: 0,
        updated: {
            date: 0,
            userId: "",
            displayName: "",
        },
    };
    if (params) {
        (0, merge_1.assign)(data, params);
    }
    return data;
}
