const math = require("mathjs");

/**
 * Convert any string into a number
 */
function toNumber(str) {
    if (!str) {
        return 0;
    }
    if (typeof str === "number") {
        return str;
    }
    return +str.replace(/[^\d.-]/g, "");
}
exports.toNumber = toNumber;

/**
 * convert any string or number into a price
 */
function toPrice(num, character) {
    character = typeof character !== "undefined" ? character : "";

    var fl = parseFloat(roundPrice(num)).toFixed(2);
    return character + fl.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
exports.toPrice = toPrice;

/**
 * Rounds off a number to use as a price
 *
 * @param {number} value
 * @returns {number}
 */
function roundPrice(value) {
    const num = toNumber(value);
    return Math.round(num * 100) / 100;
}
exports.roundPrice = roundPrice;

/**
 *
 * @param {number} value
 * @param {number} [precision]
 *
 * @returns {number}
 */
function roundNumber(value, precision) {
    return math.round(value, typeof precision === "number" ? precision : 0);
}
exports.roundNumber = roundNumber;

/**
 *
 * @param {number} value
 * @returns
 */
function addThousandsSep(value) {
    var fl = value.toString();

    const regex = /\B(?=(\d{3})+(?!\d))/g;
    if (fl.includes(".")) {
        const [num, ...dec] = fl.split(".");
        return `${num.replace(regex, ",")}.${dec.join(".")}`;
    }
    return fl.replace(regex, ",");
}
exports.addThousandsSep = addThousandsSep;

/**
 * Calculates the percent change of 2 values
 *
 * Not to be confused with percent difference
 */
function calcPercentChange(oldValue, newValue) {
    if (oldValue === 0) {
        return newValue >= 0 ? Infinity : -Infinity;
    }

    return ((newValue - oldValue) / Math.abs(oldValue)) * 100;
}
exports.calcPercentChange = calcPercentChange;

/**
 * Calculates the percent difference between 2 numbers
 *
 * @param {number} val1
 * @param {number} val2
 * @returns
 */
function calcPercentDifference(val1, val2) {
    return Math.abs(((val1 - val2) / ((val1 + val2) / 2)) * 100);
}
exports.calcPercentDifference = calcPercentDifference;

/**
 * Calculate the percent of tasks completed.
 *
 * @param {number} completed
 * @param {number} total
 * @returns
 */
function calcPercentCompleted(completed, total) {
    return (completed / total) * 100;
}
exports.calcPercentCompleted = calcPercentCompleted;

function inchesToFootInch(inches) {
    const ft = Math.floor(inches / 12);
    const inch = inches % 12;

    if (ft) {
        return `${ft}' ${inch}"`;
    }
    return `${inch}"`;
}
exports.inchesToFootInch = inchesToFootInch;

/**
 * Display the weight in lbs or kgs
 *
 * @param {number} weight the input weight in lbs
 * @param {"lbs" | "kg"} [units] the unit to output the weight in
 * @param {number} [decimals]
 * @returns
 */
function displayWeight(weight, units, decimals) {
    // setup the unit
    const unit = math.unit(weight, "lbs");
    const value = unit.toNumber(units || "lbs");

    return addThousandsSep(roundNumber(value, decimals));
}
exports.displayWeight = displayWeight;
