"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addPercentage = addPercentage;
exports.removePercentage = removePercentage;
exports.discountPercent = discountPercent;
exports.percentMarkup = percentMarkup;
exports.roundPrice = roundPrice;
exports.toNumber = toNumber;
exports.calculateItemWeight = calculateItemWeight;
const mathjs_1 = require("mathjs");
/**
 * Add the given percentage to the value
 *
 * @param  price
 * @param  percentage
 *
 */
function addPercentage(price, percentage) {
    return price * (percentage / 100 + 1);
}
function removePercentage(price, percentage) {
    const discount = price * (percentage / 100);
    return price - discount;
}
/**
 * Calculates the percentage difference between 2 values
 *
 * @param  oldPrice
 * @param  newPrice
 *
 */
function discountPercent(oldPrice, newPrice) {
    const diff = oldPrice - newPrice;
    return (diff / oldPrice) * 100;
}
/**
 * Calculate the markup percent
 *
 * @param  cost
 * @param  sellingPrice
 *
 * @returns {number}
 */
function percentMarkup(cost, sellingPrice) {
    let profit = sellingPrice - cost;
    return (profit / cost) * 100;
}
/**
 * Round off the number to a price precision
 *
 * @param  price
 * @param formula the formula to use to round off the price
 */
function roundPrice(price, formula) {
    if (formula) {
        // parse the formula expression
        price = (0, mathjs_1.evaluate)(formula, { price });
    }
    return (0, mathjs_1.round)(price, 2);
}
/**
 * Convert any string into a number
 */
function toNumber(str) {
    if (!str) {
        return 0;
    }
    if (typeof str === "number") {
        return str;
    }
    return +str.replace(/[^\d.-]/g, "");
}
/**
 * Calculate the weight of the item and return the value in lbs
 *
 * @param quantity
 * @param itemWeight
 * @param unitQuantity
 * @param units
 */
function calculateItemWeight(quantity, itemWeight, unitQuantity, units) {
    unitQuantity = typeof unitQuantity === "number" ? unitQuantity : 1;
    const qty = quantity * unitQuantity;
    let totalWeight = 0;
    if (units === "kgs") {
        totalWeight = (0, mathjs_1.evaluate)(`${qty} kg to lb`).toNumber();
    }
    else if (units === "lbs") {
        totalWeight = qty;
    }
    else if ((0, mathjs_1.isComplex)(units)) {
        totalWeight = qty * itemWeight;
    }
    else {
        totalWeight = quantity * itemWeight;
    }
    return (0, mathjs_1.round)(totalWeight, 2);
}
