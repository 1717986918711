"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventoryPriceCategory = void 0;
exports.getCategory = getCategory;
exports.isChild = isChild;
exports.isCircularRef = isCircularRef;
const merge_1 = require("@walls/merge");
const QuoteRequestMaterial_1 = require("./quotes/quoteRequest/QuoteRequestMaterial");
/**
 * The Price Category used for the material.
 *
 * This will be mainly used when printing files and will not be a part of the loaded InventoryItem
 */
class InventoryPriceCategory {
    constructor(data) {
        /**
         * The price category name
         */
        this.name = "";
        /**
         * The price category id that is used for the price category.
         *
         * This is the old value that was used in v1
         */
        this.categoryId = "";
        /**
         * The panel id for this category
         */
        this.panelId = "";
        /**
         * Set our items cost values
         *
         * @deprecated
         */
        this.itemCost = {
            buyingPrice: 0,
            buyingPriceUSD: 0,
            exchangeRate: 1,
            weight: 0,
        };
        /**
         * @deprecated
         */
        this.panelCost = {
            buyingPrice: 0,
            buyingPriceUSD: 0,
            exchangeRate: 1,
            weight: 0,
        };
        /**
         * The price to fallback to if the item doesn't exist for this given panel type
         */
        this.fallbackCategory = "default";
        /**
         * The list of available colours for the price category
         */
        this.colours = [];
        /**
         * The name that is used when printing the price list
         */
        this.printName = "";
        /**
         * The printed title used when printing a quote
         */
        this.printTitle = "";
        /**
         * The descriptive text used when printing a quote
         *
         * @example '28 Gauge Glossy ABM Profile Panel'
         */
        this.printDescription = "";
        /**
         * The profile name. This name will be shared between multiple panels and can be used to group different prices of the same profile
         *
         * @example "ABM" | "M Panel" | "Standing Seam"
         */
        this.profileName = "";
        /**
         * Tells if the price category is available for online ordering
         */
        this.available = {
            online: false,
            contractors: false,
        };
        /**
         * Tells where the material can be used
         */
        this.application = {
            roofing: true,
            siding: true,
        };
        /**
         * last edit value for the category
         */
        this.lastedit = 0;
        /**
         * Tells if the material is archived or not
         */
        this.archived = false;
        if (data) {
            this.update(data);
        }
    }
    /**
     * Updates this object by merging in the given object
     *
     * @param data the partial data to update this object
     */
    update(data) {
        Object.assign(this, data);
    }
    clone() {
        const data = (0, merge_1.clone)(this, {});
        return new InventoryPriceCategory(data);
    }
    createQuoteRequestMaterial() {
        return new QuoteRequestMaterial_1.QuoteRequestMaterialModel({
            materialId: this.categoryId,
            name: this.name,
            printTitle: this.printTitle,
            printDescription: this.printDescription,
            panelId: this.panelId,
            colours: this.colours,
            profile: this.profileName,
            application: {
                roofing: true,
                siding: true,
                ...this.application,
            },
        });
    }
}
exports.InventoryPriceCategory = InventoryPriceCategory;
/**
 * Get the price category by id
 *
 * @param  list
 * @param  categoryId
 *
 */
function getCategory(list, categoryId) {
    for (const item of list) {
        if (item.categoryId === categoryId) {
            return item;
        }
    }
    return null;
}
/**
 * Checks if the target discount category is a child of the source discount
 *
 * @param list the list of discount categories
 * @param source the source to check
 * @param target check if target is child of source
 */
function isChild(list, source, target, visited = []) {
    // check if immediate child
    if (target.fallbackCategory === source.categoryId) {
        return true;
    }
    if (target.fallbackCategory &&
        target.fallbackCategory !== target.categoryId) {
        const newTarget = getCategory(list, target.fallbackCategory);
        if (newTarget) {
            if (visited.includes(newTarget.categoryId)) {
                return true;
            }
            visited.push(newTarget.categoryId);
            return isChild(list, source, newTarget, visited);
        }
    }
    return false;
}
/**
 * Check if the source and target are a circular reference
 *
 * @param list the list to search in
 * @param source the source element
 * @param target the target element
 */
function isCircularRef(list, source, target) {
    return isChild(list, source, target) && isChild(list, target, source);
}
