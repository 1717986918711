"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactModel = void 0;
const merge_1 = require("@walls/merge");
const NotesModel_1 = require("../NotesModel");
const AddressDetails_1 = require("./AddressDetails");
const ContactDetails_1 = require("./ContactDetails");
const ContactSettings_1 = require("./ContactSettings");
class ContactModel {
    constructor(data) {
        this.uuid = "";
        /**
         * The display name used for the contact
         */
        this.displayName = "";
        /**
         * The list of contacts that are merged into this contact.
         *
         * Also lookup in this list when fetching a specific contact
         */
        this.mergedUuids = [];
        /**
         * Tells if the contact is a customer and can purchase
         */
        this.isCustomer = false;
        /**
         * Tells if the contact is a vendor that we purchase from
         */
        this.isVendor = false;
        /**
         * The list of contact methods for the primary contact
         */
        this.contactDetails = [];
        /**
         * The address details
         */
        this.address = (0, AddressDetails_1.createAddressDetails)({});
        /**
         * The linked Physical Location.
         *
         * This can be upgrade to LinkedBuilding when needed.
         */
        this.preferredLocation = {
            locationId: "",
            locationName: "",
        };
        /**
         * Create the default contact settings
         */
        this.settings = (0, ContactSettings_1.createContactSettings)({});
        /**
         * A list of notes details for the contact
         */
        this.notes = [];
        /**
         * The linked odoo partner details
         */
        this.odooPartner = {
            id: null,
            name: "",
        };
        /**
         * Sets the details about when the Contact is created
         */
        this.created = {
            date: Math.floor(Date.now() / 1000),
            userId: "",
            displayName: "",
        };
        /**
         * Sets the details about when the contact was updated
         */
        this.lastedit = {
            date: Math.floor(Date.now() / 1000),
            userId: "",
            displayName: "",
        };
        /**
         * Sets the details about when a contact is merged.
         *
         * The contact id is the destination merge.
         *
         * When active, this contact will no longer be displayed in lists.
         */
        this.merged = {
            active: false,
            contactId: "",
            date: 0,
            userId: "",
            displayName: "",
        };
        this.deleted = {
            active: false,
            date: 0,
            userId: "",
            displayName: "",
        };
        if (data) {
            this.update(data);
        }
    }
    /**
     * Update the model data.
     * Gets called when the object is first initialized with data
     * @param data
     */
    update(data) {
        // update the contact details
        if (data.contactDetails) {
            this.contactDetails = data.contactDetails.map(contact => (0, ContactDetails_1.createContactDetails)(contact));
            delete data.contactDetails;
        }
        // update the address details
        if (data.address) {
            this.address = (0, AddressDetails_1.createAddressDetails)(data.address);
            delete data.address;
        }
        // update the contact settings
        if (data.settings) {
            this.settings = (0, ContactSettings_1.createContactSettings)(data.settings);
            delete data.settings;
        }
        // update the note details for the contact
        if (data.notes) {
            this.notes = data.notes.map((data) => {
                const defaultNote = {
                    note: new NotesModel_1.NotesModel({}),
                    options: {
                        addToFile: false,
                        persistentPopup: false,
                        popup: false,
                    },
                };
                // update the note details
                if (data.note) {
                    defaultNote.note.update(JSON.parse(JSON.stringify(data.note)));
                    delete data.note;
                }
                // assign the remaining values
                const details = (0, merge_1.assign)(defaultNote, data);
                return details;
            });
            delete data.notes;
        }
        // assign the remaining  values
        (0, merge_1.assign)(this, data);
    }
    /**
     * Create a clone of the model
     *
     * @returns
     */
    clone() {
        const data = JSON.parse(JSON.stringify(this));
        return new ContactModel(data);
    }
    /**
     * Get the details with the given uuid. If not set gets the default value
     *
     * Will return null if it can't create a match
     *
     * @param uuid
     */
    getDetails(uuid) {
        return (0, ContactDetails_1.getContactDetailsFromList)(this.contactDetails, uuid);
    }
}
exports.ContactModel = ContactModel;
