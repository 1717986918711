"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createColourOverride = createColourOverride;
const merge_1 = require("@walls/merge");
function createColourOverride(data) {
    const defaultData = {
        colour: "",
        useColour: "",
        productImages: [],
        weight: 0,
        upcCode: "",
        deleted: false,
        pricing: {
            cost: 0,
        },
    };
    return (0, merge_1.assign)(defaultData, data);
}
