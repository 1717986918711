// import all style sheets
import "./style.scss";

// eslint-disable-next-line no-undef
require("./../env.js");

// eslint-disable-next-line no-unused-vars
const sentry = require("sentry");

// setup the fetch function
const { getBearerToken } = require("users/firebase/getBearerToken");
const { setupAuthTokenCallback } = require("./common/replaceGlobalFetch");
setupAuthTokenCallback(getBearerToken);

require("set-value");
require("get-value");
require("@walls/merge");
require("dexie");
require("data-models/index");
require("data-models/InventoryItem/index");
require("data-models/DiscountCategory");
require("data-models/InventoryPriceCategory");

// set the mathjs globals
const math = require("mathjs");
global.math = math;

require("moment");
