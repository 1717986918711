"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestProductModel = void 0;
const merge_1 = require("@walls/merge");
const TechnicalFormulas_1 = require("../../InventoryItem/TechnicalFormulas");
class QuoteRequestProductModel {
    constructor(data) {
        /**
         * The primary identifier
         */
        this.productId = "";
        /**
         * The display name used on the website for the product
         */
        this.displayName = "";
        /**
         * The description text used on the website for the product
         */
        this.description = "";
        /**
         * If this is a child item then set the parent id
         */
        this.parentId = null;
        /**
         * A key-value pairing for the material to product relation ships.
         *
         * Use this mapping to check which product to map to.
         *
         * The key is the priceCategory id, and the value is the product id.
         */
        this.materialMapping = null;
        /**
         * The list of potential parent ids for the given product
         */
        this.materialParents = [];
        /**
         * The linked material. Same as priceCategory from InventoryItem
         */
        this.material = {
            materialId: "",
            materialName: "",
        };
        /**
         * The type of product this is for
         */
        this.productType = "generic";
        /**
         * if this is a panel, the width of the panel in inches
         */
        this.panelWidth = 0;
        /**
         * Provides the given technical information for this item
         */
        this.technical = null;
        /**
         * Constraints set on the product.
         *
         * If the value is null, then ignore it
         */
        this.constraints = {
            quantity: { min: 0, max: 0 },
            sheetLength: { min: 0, max: 0 },
        };
        /**
         * The product pricing details
         */
        this.pricing = {
            isTaxable: true,
            unitPrice: 0,
        };
        /**
         * the url for the product icon
         */
        this.productIcon = "";
        /**
         * The list of product images available
         */
        this.productImages = [];
        /**
         * A list of product images grouped by colour
         */
        this.imagesByColour = [];
        if (data) {
            this.update(data);
        }
    }
    /**
     * Update the model data.
     * Gets called when the object is first initialized with data
     * @param data
     */
    update(data) {
        if (data.technical) {
            this.technical = (0, TechnicalFormulas_1.setupProductTechnical)(data.technical);
            delete data.technical;
        }
        if (data.imagesByColour) {
            this.imagesByColour = data.imagesByColour.map(item => {
                const defaultValue = {
                    colour: "",
                    productImages: [],
                };
                return {
                    ...defaultValue,
                    ...item,
                };
            });
            delete data.imagesByColour;
        }
        (0, merge_1.assign)(this, data);
    }
    /**
     * Create a clone of the model
     *
     * @returns
     */
    clone() {
        const data = JSON.parse(JSON.stringify(this));
        return new QuoteRequestProductModel(data);
    }
    /**
     * Get the icon url for the product
     *
     * @param colour
     * @returns
     */
    getIconUrl(colour) {
        // check for a product icon
        if (this.productIcon) {
            return this.productIcon;
        }
        // try to match the product image to a colour specific image
        if (colour) {
            const images = this.imagesByColour.find(value => value.colour === colour);
            if (images && images.productImages.length > 0) {
                return images.productImages[0];
            }
        }
        // fallback to the product image
        if (this.productImages.length > 0) {
            return this.productImages[0];
        }
        // no image found
        return "";
    }
    /**
     * Get the given formula value from the product
     *
     * @param name the name to match to
     */
    getFormula(name) {
        if (this.technical && this.technical.formulas.length > 0) {
            if (name) {
                // match the formula name
                for (const value of this.technical.formulas) {
                    if (value.name === name) {
                        return value;
                    }
                }
            }
            // get the default formula
            for (const value of this.technical.formulas) {
                if (value.default) {
                    return value;
                }
            }
            // return the first formula value as final resort
            return this.technical.formulas[0];
        }
        return null;
    }
}
exports.QuoteRequestProductModel = QuoteRequestProductModel;
