"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestMaterialModel = void 0;
const merge_1 = require("@walls/merge");
class QuoteRequestMaterialModel {
    constructor(data) {
        /**
         * The material id
         */
        this.materialId = "";
        /**
         * The material name
         */
        this.name = "";
        /**
         * The printed title used when printing a quote
         */
        this.printTitle = "";
        /**
         * The descriptive text used when printing a quote
         *
         * @example '28 Gauge Glossy ABM Profile Panel'
         */
        this.printDescription = "";
        /**
         * The panel tied to this material
         */
        this.panelId = "";
        /**
         * The list of available colours for this material
         */
        this.colours = [];
        /**
         * The profile name for this material
         */
        this.profile = "";
        /**
         * The image url for this material profile
         */
        this.profileImage = "";
        /**
         * The lowest price for this material
         */
        this.panelPrice = 0;
        /**
         * Tells where the material can be used
         */
        this.application = {
            roofing: true,
            siding: true,
        };
        if (data) {
            this.update(data);
        }
    }
    /**
     * Update the model data.
     * Gets called when the object is first initialized with data
     * @param data
     */
    update(data) {
        (0, merge_1.assign)(this, data);
    }
    /**
     * Create a clone of the model
     *
     * @returns
     */
    clone() {
        const data = JSON.parse(JSON.stringify(this));
        return new QuoteRequestMaterialModel(data);
    }
}
exports.QuoteRequestMaterialModel = QuoteRequestMaterialModel;
