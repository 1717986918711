"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComplexUnits = exports.UnitsListMapping = void 0;
exports.getUnitTypes = getUnitTypes;
exports.createProductUnitsListItem = createProductUnitsListItem;
exports.calculateBaseUnitQuantity = calculateBaseUnitQuantity;
const math = __importStar(require("mathjs"));
/**
 * The list of units of measure
 */
exports.UnitsListMapping = {
    qty: "qty",
    boxes: "boxes",
    kgs: "kgs",
    lbs: "lbs",
    in: "ft",
    "sq/ft": "sq/ft",
    m: "meter",
    "sq/m": "m²",
};
/**
 * Complex units use a secondary unit value to determine the final quantity
 */
exports.ComplexUnits = [
    "in",
    "kgs",
    "lbs",
    "m",
    "sq/ft",
    "sq/m",
];
/**
 * Get the unit type. Can be used to determine how to render the units
 *
 * @param unit
 * @returns
 */
function getUnitTypes(unit) {
    switch (unit) {
        case "in":
        case "m":
            return "length";
        case "sq/ft":
        case "sq/m":
            return "area";
        case "kgs":
        case "lbs":
            return "weight";
        case "boxes":
        case "qty":
        default:
            return "basic";
    }
}
/**
 * Create the data for the ProductUnitsListItem
 *
 * @param data
 * @returns
 */
function createProductUnitsListItem(data) {
    return {
        unit: "qty",
        multiplier: 1,
        archived: false,
        ...data,
    };
}
/**
 * Calculate the base quantity to use as consumption
 *
 * @param unit the current unit of measure
 * @param unitMultiplier the multiplier for the unit of measure
 * @param quantity the normal quantity for the product
 * @param unitsQuantity the units quantity, such as lbs, kgs, length
 * @returns
 */
function calculateBaseUnitQuantity(unit, unitMultiplier, quantity, unitsQuantity) {
    // check if the given unit is complex
    const isComplex = exports.ComplexUnits.includes(unit);
    // combine the unitsQuantity and quantity if complex
    const combinedQuantity = isComplex ? quantity * unitsQuantity : quantity;
    // calculate the new base quantity
    const baseQuantity = math.round(combinedQuantity / unitMultiplier, 6);
    return { combinedQuantity, baseQuantity };
}
