"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComplexUnits = exports.UnitsListMapping = void 0;
exports.getUnitTypes = getUnitTypes;
exports.getConversionUnit = getConversionUnit;
exports.getAvailableUnitsOfMeasureList = getAvailableUnitsOfMeasureList;
exports.createProductUnitsListItem = createProductUnitsListItem;
exports.calculateBaseUnitQuantity = calculateBaseUnitQuantity;
exports.convertUnitOfMeasure = convertUnitOfMeasure;
exports.calculateBaseQuantity = calculateBaseQuantity;
exports.addUnitsOfMeasure = addUnitsOfMeasure;
const numbers_1 = require("format/numbers");
const math = __importStar(require("mathjs"));
/**
 * The list of units of measure
 */
exports.UnitsListMapping = {
    qty: "qty",
    boxes: "boxes",
    kgs: "kgs",
    lbs: "lbs",
    in: "ft",
    "sq/ft": "sq/ft",
    m: "meter",
    "sq/m": "m²",
    tonne: "M/T",
};
/**
 * Complex units use a secondary unit value to determine the final quantity
 */
exports.ComplexUnits = [
    "in",
    "kgs",
    "lbs",
    "m",
    "sq/ft",
    "sq/m",
];
/**
 * Get the unit type. Can be used to determine how to render the units
 *
 * @param unit
 * @returns
 */
function getUnitTypes(unit) {
    switch (unit) {
        case "in":
        case "m":
            return "length";
        case "sq/ft":
        case "sq/m":
            return "area";
        case "kgs":
        case "lbs":
            return "weight";
        case "tonne":
            return "single-weight";
        case "boxes":
        case "qty":
        default:
            return "basic";
    }
}
/**
 * Convert our units into the evaluation unit for mathjs
 *
 * @param unit
 */
function getConversionUnit(unit) {
    switch (unit) {
        case "in":
            return "ft";
        case "m":
            return "m";
        case "sq/ft":
            return "sqft";
        case "sq/m":
            return "m2";
        case "kgs":
            return "kg";
        case "lbs":
            return "lb";
        case "tonne":
            return "tonne";
        case "boxes":
        case "qty":
            return "";
        default:
            return unit;
    }
}
/**
 * Get the list of available units of measure
 *
 * @param unitsOfMeasure the units of measure details for the product
 * @param defaultAll when true will return the default list of units if none are set
 */
function getAvailableUnitsOfMeasureList(unitsOfMeasure, defaultAll) {
    const list = unitsOfMeasure.list.filter(unit => !unit.archived);
    // return the list of units
    if (list.length > 0) {
        return list.map(unit => unit.unit);
    }
    if (defaultAll) {
        return Object.keys(exports.UnitsListMapping);
    }
    return [];
}
/**
 * Create the data for the ProductUnitsListItem
 *
 * @param data
 * @returns
 */
function createProductUnitsListItem(data) {
    return {
        unit: "qty",
        multiplier: 1,
        archived: false,
        ...data,
    };
}
/**
 * Calculate the base quantity to use as consumption
 *
 * @param unit the current unit of measure
 * @param unitMultiplier the multiplier for the unit of measure
 * @param quantity the normal quantity for the product
 * @param unitsQuantity the units quantity, such as lbs, kgs, length
 * @returns
 */
function calculateBaseUnitQuantity(unit, unitMultiplier, quantity, unitsQuantity) {
    // check if the given unit is complex
    const isComplex = exports.ComplexUnits.includes(unit);
    // combine the unitsQuantity and quantity if complex
    const combinedQuantity = isComplex ? quantity * unitsQuantity : quantity;
    // calculate the new base quantity
    const baseQuantity = math.round(combinedQuantity / unitMultiplier, 6);
    return { combinedQuantity, baseQuantity };
}
/**
 * Convert the input unit of measure to the output unit of measure
 *
 * In the case of a none complex input and complex output, the input.unitQuantity will be used to determine the final quantity
 *
 * @param inputValue
 * @param unitsOfMeasure
 * @param output
 * @returns
 */
function convertUnitOfMeasure(inputValue, unitsOfMeasure, output) {
    /**
     * Check if the input unit is complex
     *
     * Complex units are units that require a secondary unit to determine the final quantity
     */
    const isInputComplex = exports.ComplexUnits.includes(inputValue.unit);
    /**
     * Check if the output unit is complex
     *
     * Complex units are units that require a secondary unit to determine the final quantity
     */
    const isOutputComplex = exports.ComplexUnits.includes(output.unit);
    /**
     * The unit data for the input unit
     *
     * The multiplier is used to convert the unit to the base unit
     */
    const inputUnitData = unitsOfMeasure.list.find(u => u.unit === inputValue.unit);
    /**
     * The unit data for the output unit
     *
     * The multiplier is used to convert the base unit to the output unit
     */
    const outputUnitData = unitsOfMeasure.list.find(u => u.unit === output.unit);
    const inputMultiplier = inputUnitData ? inputUnitData.multiplier : 1;
    const outputMultiplier = inputUnitData ? outputUnitData.multiplier : 1;
    // calculate the base quantity
    if (isInputComplex && isOutputComplex) {
        // if both units are complex,
        // keep the quantity the same and convert the unitQuantity
        // this will ignore the preferredUnitQuantity
        const baseUnitQuantity = inputValue.unitQuantity / inputMultiplier;
        const outputUnitQuantity = baseUnitQuantity * outputMultiplier;
        const result = {
            quantity: inputValue.quantity,
            unitQuantity: (0, numbers_1.roundNumber)(outputUnitQuantity, 6),
            unit: output.unit,
        };
        return result;
    }
    else if (isInputComplex && !isOutputComplex) {
        // if the input unit is complex, but the output unit is not
        // convert the unitQuantity to the base unit and then to the output unit
        const baseUnitQuantity = inputValue.unitQuantity / inputMultiplier;
        const outputUnitQuantity = baseUnitQuantity * outputMultiplier;
        const result = {
            quantity: (0, numbers_1.roundNumber)(inputValue.quantity * outputUnitQuantity, 6),
            unitQuantity: 0,
            unit: output.unit,
        };
        return result;
    }
    else if (!isInputComplex && isOutputComplex) {
        // if the input unit is not complex, but the output unit is
        // convert the quantity to the base unit and then to the output unit
        // use the preferredUnitQuantity if available
        const baseQuantity = inputValue.quantity / inputMultiplier;
        const combinedQuantity = baseQuantity * outputMultiplier;
        if (output.preferredUnitQuantity) {
            const result = {
                quantity: (0, numbers_1.roundNumber)(combinedQuantity / output.preferredUnitQuantity, 6),
                unitQuantity: output.preferredUnitQuantity,
                unit: output.unit,
            };
            return result;
        }
        const inputUnitQuantity = inputValue.unitQuantity || 1;
        const result = {
            quantity: (0, numbers_1.roundNumber)(combinedQuantity / inputUnitQuantity, 6),
            unitQuantity: inputUnitQuantity,
            unit: output.unit,
        };
        return result;
    }
    else {
        // if both units are not complex
        // convert the quantity to the base unit and then to the output unit
        const baseQuantity = inputValue.quantity / inputMultiplier;
        const outputQuantity = baseQuantity * outputMultiplier;
        const result = {
            quantity: (0, numbers_1.roundNumber)(outputQuantity, 6),
            unitQuantity: 0,
            unit: output.unit,
        };
        return result;
    }
}
/**
 * Convert the unit to the base unit for the product
 *
 * @param inputValue
 * @param unitsOfMeasure
 * @returns
 */
function calculateBaseQuantity(inputValue, unitsOfMeasure) {
    return convertUnitOfMeasure(inputValue, unitsOfMeasure, {
        unit: unitsOfMeasure.primaryUnit,
    });
}
/**
 * Add up the list of units of measure
 *
 * @param list
 * @param unitsOfMeasure
 * @param convertTo
 * @returns
 */
function addUnitsOfMeasure(list, unitsOfMeasure, convertTo) {
    const convertedList = list
        .map(value => {
        return convertUnitOfMeasure(value, unitsOfMeasure, {
            unit: convertTo.unit,
        });
    })
        .filter(Boolean);
    const result = {
        quantity: 0,
        unitQuantity: convertTo.unitQuantity,
        unit: convertTo.unit,
    };
    if (!exports.ComplexUnits.includes(convertTo.unit)) {
        for (const item of convertedList) {
            result.quantity += item.quantity;
        }
        return result;
    }
    let totalCombined = 0;
    for (const item of convertedList) {
        const combined = item.quantity * item.unitQuantity;
        totalCombined += combined;
    }
    const quantity = (0, numbers_1.roundNumber)(totalCombined / convertTo.unitQuantity, 6);
    const unitQuantity = quantity === 0
        ? convertTo.unitQuantity
        : (0, numbers_1.roundNumber)(totalCombined / quantity, 6);
    return {
        quantity,
        unitQuantity,
        unit: result.unit,
    };
}
