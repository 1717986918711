"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeLists = mergeLists;
/**
 * Check if the given item is already in the list. If not then add it
 *
 * @param list the list to add to
 * @param data the data to add to list
 * @param key the key to match to
 */
function mergeLists(list, data, key, decider) {
    for (const d of data) {
        // Get the existing item from the original list
        const existing = getListItem(list, key, d[key]);
        decider(list, d, existing);
    }
    return list;
}
function getListItem(list, key, value) {
    for (const item of list) {
        if (item[key] === value) {
            return item;
        }
    }
    return null;
}
