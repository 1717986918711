"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAddressDetails = createAddressDetails;
const merge_1 = require("@walls/merge");
function createAddressDetails(data) {
    // setup the default details for the address
    const defaultValue = {
        value: "",
        lookupQuery: "",
        placeCoords: "",
        placeId: "",
        drivingDetails: [],
    };
    // setup the driving details
    if (data.drivingDetails) {
        defaultValue.drivingDetails = data.drivingDetails.map(data => {
            const defaultDetails = {
                origin: "",
                distance: 0,
                duration: 0,
            };
            return (0, merge_1.assign)(defaultDetails, data);
        });
        delete data.drivingDetails;
    }
    return (0, merge_1.assign)(defaultValue, data);
}
