"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileNotesModel = exports.NotesModel = void 0;
exports.createNoteAttachment = createNoteAttachment;
exports.mergeNoteAttachments = mergeNoteAttachments;
const merge_1 = require("@walls/merge");
const addToList_1 = require("./functions/addToList");
class NotesModel {
    constructor(data) {
        /**
         * The uuid for the note
         */
        this.uuid = "";
        /**
         * details about when the note was created
         */
        this.created = {
            active: true,
            date: Math.floor(Date.now() / 1000),
            displayName: "",
            userId: "",
        };
        /**
         * details about when the note was marked as completed
         */
        this.completed = null;
        /**
         * A list of tags for this note
         */
        this.tags = [];
        /**
         * Tell if the note was deleted or not
         */
        this.deleted = false;
        /**
         * The plain text note content
         */
        this.note = "";
        /**
         * The quill editor data to show
         */
        this.quillDelta = {};
        /**
         * List of users that are tagged in the note
         */
        this.taggedUsers = [];
        /**
         * Tell if the primary note is still waiting for new attachments.
         * This is used when a note is already saved but still waiting for attachments to upload
         */
        this.hasAttachments = false;
        /**
         * the list of pictures added to the notes
         */
        this.attachments = [];
        /**
         * The time it was last edited
         */
        this.lastedit = Math.floor(Date.now() / 1000);
        /**
         * Current version number for the note data
         */
        this.version = 2;
        if (data) {
            this.update(data);
        }
    }
    /**
     * Update this class with the given data
     *
     * @param data the data to use to update this class
     */
    update(data) {
        // setup the list of tags in the notes
        if (data.tags) {
            const tags = data.tags.map(tag => {
                // setup the default tagged data
                const defaultValue = {
                    category: "",
                    date: 0,
                    userId: "",
                    displayName: "",
                };
                return (0, merge_1.assign)(defaultValue, tag);
            });
            this.tags = tags;
            delete data.tags;
        }
        // setup the list of tagged users
        if (data.taggedUsers) {
            // complete the partial data
            const taggedUsers = data.taggedUsers.map(details => {
                // setup the default tagged data
                const defaultValue = {
                    userId: "",
                    displayName: "",
                    taggedBy: {
                        userId: "",
                        displayName: "",
                        date: 0,
                    },
                };
                return (0, merge_1.assign)(defaultValue, details);
            });
            (0, addToList_1.mergeLists)(this.taggedUsers, taggedUsers, "userId", (list, tagged, existing) => {
                if (existing) {
                    (0, merge_1.assign)(existing, tagged);
                }
                else {
                    list.push(tagged);
                }
            });
            delete data.taggedUsers;
        }
        // setup the notes attachments
        if (data.attachments) {
            // complete the partial data
            const attachments = data.attachments.map(attachment => {
                return createNoteAttachment(attachment);
            });
            this.attachments = attachments;
            delete data.attachments;
        }
        (0, merge_1.assign)(this, data);
    }
    /**
     * Clone the given note data
     */
    clone() {
        const data = (0, merge_1.clone)(this);
        return new NotesModel(data);
    }
    /**
     * Check if the note is completed
     */
    isCompleted() {
        return this.completed && this.completed.active ? true : false;
    }
    isAttachmentNote() {
        return this.attachments.length > 0 || this.hasAttachments;
    }
}
exports.NotesModel = NotesModel;
class FileNotesModel extends NotesModel {
    constructor(data) {
        super();
        /**
         * the linked documents for the note
         *
         * This is only needed if the note is stored in the general collection storage
         */
        this.linkedDocument = {
            documentId: "",
            documentType: "",
        };
        if (data) {
            this.update(data);
        }
    }
    update(data) {
        if (data.linkedDocument) {
            (0, merge_1.assign)(this.linkedDocument, data.linkedDocument);
            delete data.linkedDocument;
        }
        super.update(data);
    }
    clone() {
        const data = JSON.parse(JSON.stringify(this));
        return new FileNotesModel(data);
    }
    createAttachmentLink(fileName) {
        const docId = this.linkedDocument.documentId;
        const docType = this.linkedDocument.documentType;
        if (docType === "PO" || docType === "PS") {
            return `/uploads/purchase/notes/${docId}/${fileName}`;
        }
        // not a valid document type
        return "";
    }
}
exports.FileNotesModel = FileNotesModel;
/**
 * Creates the attachment data for the notes
 *
 * @param data
 * @returns
 */
function createNoteAttachment(data) {
    const defaultValue = {
        filename: "",
        filetype: "image",
        highQuality: "",
        title: "",
    };
    return (0, merge_1.assign)(defaultValue, data);
}
/**
 * Will merge the incoming attachments into the original by filename.
 *
 * When duplicates are found, the incoming will be merged, overwriting the original
 *
 * @param original
 * @param incoming
 */
function mergeNoteAttachments(original, incoming) {
    const list = JSON.parse(JSON.stringify(original));
    for (const inAttach of incoming) {
        const index = list.findIndex(attach => attach.filename === inAttach.filename);
        if (index >= 0) {
            (0, merge_1.assign)(list[index], inAttach);
        }
        else {
            list.push(inAttach);
        }
    }
    return list;
}
