"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = flatDeep;
/**
 * Flatten the given array
 *
 * @param arr
 * @param d
 */
function flatDeep(arr, d = 1) {
    return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
        : arr.slice();
}
;
