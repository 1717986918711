"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupProductUpcItems = setupProductUpcItems;
/**
 * Setup the default data for the ProductUpcCode interface
 * @param data
 * @returns
 */
function setupProductUpcItems(data) {
    const defaultData = { colour: "", uom: "", upc: "" };
    return Object.assign(defaultData, data);
}
